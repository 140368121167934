<template>
  <tp-modal v-if="true" name="modal-goods-destruction" max-width="768px">
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">
          Hủy hàng {{ goodsDestruction.id ? goodsDestruction.code : null }}
        </div>
        <v-spacer></v-spacer>
        <!-- <v-btn
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-3"
          depressed
          @click="cancelGoodsReceipt(props.payload.index)"
        >
          Hủy
        </v-btn> -->
        <v-btn
          v-if="!goodsDestruction.id"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          :loading="
            goodsDestructionStatusRequest.value ===
              'loading-createGoodsDestruction'
          "
          @click="
            goodsDestructionStatusRequest.value ===
            'loading-createGoodsDestruction'
              ? null
              : createGoodsDestruction()
          "
        >
          Thực hiện
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-row class="mx-0">
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Chi nhánh</div>
              <div class="py-1">
                {{
                  goodsDestruction.branch_name
                    ? goodsDestruction.branch_name
                    : "Chưa chọn serial"
                }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Nhân viên hủy</div>
              <div class="py-1">
                {{
                  goodsDestruction.id
                    ? goodsDestruction.created_user_name
                    : (user && user.name) || null
                }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Ngày hủy</div>
              <div class="py-1">
                {{
                  goodsDestruction.id ? goodsDestruction.created_at : getToday()
                }}
              </div>
            </v-col>
            <v-col class="pa-5" v-if="false">
              <div class="font-weight-bold mb-1">Trạng thái</div>
              <div>
                <v-chip
                  class="text-body-2 font-weight-bold px-2"
                  :color="
                    goodsDestruction.status === 1 ? 'green' : 'red accent-2'
                  "
                  outlined
                  small
                >
                  {{ goodsDestruction.status === 1 ? "Đã nhập" : "Đã hủy" }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mt-5 pt-4" flat v-if="!goodsDestruction.id">
          <v-row class="mx-0">
            <v-col class="py-0 px-5" cols="12">
              <div class="font-weight-bold mb-2">
                Serial hủy
              </div>
              <v-text-field
                v-model="goodsDestruction.serial_number"
                class="text-body-1"
                clearable
                dense
                :error="
                  serialStatusRequest.value === 'error-getSerialBySerialNumber'
                "
                :error-messages="serialStatusRequest.message"
                outlined
                placeholder="Nhập serial và nhấn enter"
                single-line
                @keypress.enter="getSerialInfo(goodsDestruction.serial_number)"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 px-5" cols="12">
              <div class="font-weight-bold mb-2">
                Ghi chú
              </div>
              <v-textarea
                v-model="goodsDestruction.note"
                class="text-body-1"
                auto-grow
                dense
                outlined
                placeholder="Nhập ghi chú"
                rows="2"
                single-line
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mt-5" flat>
          <v-card-title class="justify-center px-5">
            Thông tin sản phẩm hủy
          </v-card-title>

          <div class="px-5 pb-4" v-if="goodsDestruction.option.SKU">
            <div class="mb-1">
              <span class="font-weight-bold">Tên sản phẩm:</span>
              {{
                goodsDestruction.option.product_name
                  ? goodsDestruction.option.product_name
                  : "Chưa có thông tin"
              }}
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">SKU:</span>
              {{ `${goodsDestruction.option.SKU} -` }}
              <span class="text-info" v-html="goodsDestruction.option.name">
                {{ goodsDestruction.option.name }}
              </span>
            </div>
            <div class="mb-1">
              <span class="font-weight-bold mr-1">Serial:</span>
              <v-chip
                class="font-weight-medium px-2"
                color="cyan lighten-4"
                label
                small
              >
                {{ goodsDestruction.serial_number }}
              </v-chip>
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">Giá nhập:</span>
              {{ goodsDestruction.created_price | formatCurrency }}
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">Giá bán:</span>
              {{ goodsDestruction.option.price | formatCurrency }}
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">Phiếu nhập:</span>
              {{ goodsDestruction.hoadonnhap_code }}
            </div>
          </div>

          <div v-else class="mx-6 pb-5">
            <div
              class="grey lighten-4 grey--text text--darken-2 d-flex align-center justify-center rounded py-5"
            >
              Chưa có serial được chọn.
            </div>
          </div>
        </v-card>

        <v-card
          v-if="goodsDestruction.id && goodsDestruction.note"
          class="mt-5"
          flat
        >
          <div class="amber lighten-5 text-body-1 px-3 py-2">
            <strong>Ghi chú:</strong> {{ goodsDestruction.note }}
          </div>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  computed: {
    goodsDestruction() {
      return this.$store.getters["GOODS_DESTRUCTION/goodsDestruction"];
    },
    goodsDestructionStatusRequest() {
      return this.$store.getters["GOODS_DESTRUCTION/statusRequest"];
    },
    serialInfo() {
      return this.$store.getters["SERIAL/serialInfo"];
    },
    serialStatusRequest() {
      return this.$store.getters["SERIAL/statusRequest"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-goods-destruction" });
    },

    async createGoodsDestruction() {
      const routeQuery = this.$route.query;

      await this.$store.dispatch("GOODS_DESTRUCTION/createGoodsDestruction", {
        data: {
          serial_id: this.goodsDestruction.serial_id,
          note: this.goodsDestruction.note
        },
        routeQuery: {
          filter: {
            branch_id:
              typeof routeQuery.branch_id === "string"
                ? [parseInt(routeQuery.branch_id)]
                : typeof routeQuery.branch_id === "object"
                ? routeQuery.branch_id.map(item => parseInt(item))
                : null,
            created_by_id:
              typeof routeQuery.created_by_id === "string"
                ? [parseInt(routeQuery.created_by_id)]
                : typeof routeQuery.created_by_id === "object"
                ? routeQuery.created_by_id.map(item => parseInt(item))
                : null,
            fromDate: routeQuery.date_from ? routeQuery.date_from : null,
            toDate: routeQuery.date_to ? routeQuery.date_to : null
          },
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (
        this.goodsDestructionStatusRequest.value ===
        "success-createGoodsDestruction"
      ) {
        this.closeModal();
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã hủy thành công"
          }
        });
      }
    },

    async getSerialInfo(serialNumber) {
      await this.$store.dispatch(
        "SERIAL/getSerialBySerialNumber",
        serialNumber
      );
      // Set goods destruction
      if (
        this.serialStatusRequest.value === "success-getSerialBySerialNumber"
      ) {
        this.goodsDestruction.branch_name = this.serialInfo.branch_name;
        this.goodsDestruction.serial_id = this.serialInfo.id;
        this.goodsDestruction.created_price = this.serialInfo.created_price;
        this.goodsDestruction.hoadonnhap_code = this.serialInfo.hdn_code;
        this.goodsDestruction.option = {
          SKU: this.serialInfo.option_sku,
          name: this.serialInfo.option_name,
          product_name: this.serialInfo.product_name,
          price: this.serialInfo.price
        };
      }
    },

    getToday() {
      const date = new Date(),
        hour = date
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = date
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        dd = date
          .getDate()
          .toString()
          .padStart(2, "0"),
        mm = (date.getMonth() + 1).toString().padStart(2, "0"),
        yyyy = date.getFullYear();

      return `${hour}:${mins} ${dd}/${mm}/${yyyy}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-info {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
</style>
